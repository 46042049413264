import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShippingConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-shipping-configuration',
  templateUrl: './shipping-configuration.component.html',
  styleUrls: ['./shipping-configuration.component.scss']
})
export class ShippingConfigrationComponent implements OnInit {
  _global = GlobalService
  shippingConfigurationForm: FormGroup;
  shippingData: ShippingConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.getShippingConfig()
    this.initForm()
  }
  getShippingConfig(){
    try{
      this._abd.showSpinner()
      this._businessSetupService.getShippingConfiguration().subscribe(res=>{
        if(res.success){
          this.shippingData = res.data
         this.setValues(this.shippingData)
          this._abd.hideSpinner()
        }
      })
    }
    catch{
      this._abd.hideSpinner()
    }
  }
  get f() {
    return this.shippingConfigurationForm.controls;
  }
  set f(values: any) {
    if (this.shippingConfigurationForm) {
      this.shippingConfigurationForm.setValue(values);
    }
  }
  setValues(values:any) {
    this.f = {
      isEnabled:values?.isEnabled,
      isEnableFlat:values?.isEnableFlat,
      rate:values?.rate,
      storePickUp:values?.storePickUp,
    }
}
  initForm() {
    this.shippingConfigurationForm = this._fb.group({
       isEnabled: [],
       isEnableFlat: [],
       rate: ["",[Validators.required,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
       storePickUp: []
     })
     this.setCustomValidator()
  }
  setCustomValidator(){
    this.validation(this.f.isEnableFlat.value)
     this.f.isEnableFlat.valueChanges.subscribe(val=>{
      this.validation(val)
      })
  }
  validation(val?:any){
    if(val === true){
      this.f.rate.setValidators([Validators.required,Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]);
       } else{
      this.f.rate.clearValidators();
       }
    this.f.rate.updateValueAndValidity();
   }
  saveFn(){
    let shippingValue: any = {
      isEnabled: this.f.isEnabled.value,
      isEnableFlat: this.f.isEnableFlat.value,
      rate: this.f.rate.value,
      storePickUp: this.f.storePickUp.value,
      isSaved:true,
    }
    try{
      this._abd.showSpinner()
      this._businessSetupService.updateShippingConfiguration(this.shippingData.id,shippingValue).subscribe(res=>{
        if(res.success){

          this.getShippingConfig()
          // this._abd.systemConfigs.shippingConfig.isSaved = true
          this._connMessageService.showToastMessage(`Shipping configuration saved successfully!`, 'success')
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving shipping configurations!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showPrintingConfiguration = false
  }

  }
  