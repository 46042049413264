    <div>
        <div>
            <div>
                <br />
                <form
                    [formGroup]="paymentProcessingForm"
                     autocomplete="off"
                >
                     <div class="flex flex-column py-2">
                        <label class="control-label mb-2"><strong>Transaction Mode <span style="color: red; font-size: 1.25rem">*</span> <i class="pi pi-info-circle text-sm px-1" pTooltip=" Choose test mode to trial run using test API keys. Switch to live mode to begin accepting payments with Stripe using live API keys."></i>
                       </strong></label>
                       <p-dropdown
                       [formControl]="$any(paymentProcessingForm.controls)['transactionMode']"
                       [style]="{width:'100%'}"
                       class="p-dropdown-1"
                       [options]="transactionModeList"
                       optionLabel="value"
                       appendTo="body"
                   >
                   </p-dropdown>
                    </div>
                    <!-- Test Mode -->
                     <div class="flex flex-column py-2" *ngIf="f.transactionMode.value.id === 'test'">
                        <label class="control-label mb-2"><strong>Test publishable key <span style="color: red; font-size: 1.25rem">*</span> <i class="pi pi-info-circle text-sm px-1" pTooltip="Get the test publishable key from your stripe account."></i>
                       </strong></label>
                       <app-text-input
                       [formControl]="$any(paymentProcessingForm.controls)['testPublishableKey']"
                       [label]=""
                       [name]="'Test Publishable Key '"
                       [description]=""
                   ></app-text-input>
                    </div>
                     <div class="flex flex-column py-2" *ngIf="f.transactionMode.value.id === 'test'">
                        <label class="control-label mb-2"><strong>Test secret key <span style="color: red; font-size: 1.25rem">*</span> <i class="pi pi-info-circle text-sm px-1" pTooltip="Get the test secret key from your stripe account."></i>
                       </strong></label>
                       <app-text-input
                       [formControl]="$any(paymentProcessingForm.controls)['testSecretKey']"
                        [label]=""
                        [name]="'Test Secret Key '"
                       [description]=""
                   ></app-text-input>
                    </div>
              <!-- /Test Mode -->
               <!-- Live Mode -->
                    <div class="flex flex-column py-2" *ngIf="f.transactionMode.value.id === 'live'">
                        <label class="control-label mb-2"><strong>Live publishable key <span style="color: red; font-size: 1.25rem">*</span> <i class="pi pi-info-circle text-sm px-1" pTooltip="Get the live publishable key from your stripe account."></i>
                       </strong></label>
                       <app-text-input
                       [formControl]="$any(paymentProcessingForm.controls)['livePublishableKey']"
                       [label]=""
                       [name]="'Live Publishable Key'"
                       [description]=""
                   ></app-text-input>
                    </div>
                     <div class="flex flex-column py-2" *ngIf="f.transactionMode.value.id === 'live'">
                        <label class="control-label mb-2"><strong>Live secret key <span style="color: red; font-size: 1.25rem">*</span> <i class="pi pi-info-circle text-sm px-1" pTooltip="Get the live secret key from your stripe account."></i>
                       </strong></label>
                       <app-text-input
                       [formControl]="$any(paymentProcessingForm.controls)['liveSecretKey']"
                        [label]=""
                        [name]="'Live Secret Key '"
                       [description]=""
                   ></app-text-input>
                    </div>
                    <br/>
<!-- /Live Mode -->

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!paymentProcessingForm.valid"
                                (click)="saveFn()"
                                ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
