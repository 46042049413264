import { UserService } from 'src/app/_services/user.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { usTimeZoneList } from 'src/app/_data/time-zones';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { LocationsService } from 'src/app/_services/locations.service';

@Component({
  selector: 'app-theme-configuration',
  templateUrl: './theme-configuration.component.html',
  styleUrls: ['./theme-configuration.component.scss']
})
export class ThemeConfigurationComponent implements OnInit {
  _globals = GlobalService
  themeList: any[] = [];
  selectedTheme = null;
  isShow:boolean = true;
 
 
  constructor(
    public _abd: AppBaseDataService,
    public _businessSetup: BusinessSetupService,
    public _userService: UserService,
    private _router: Router,
    private _connMessageService: ConnMessageService,
    public _locationService: LocationsService,
  ){}
 
  ngOnInit(): void {
    // this.getThemeConfiguration()

  this.selectedTheme = this._globals.webStoreConfiguration.filter(v=>v.isDefault === true)[0];



    // if(localStorage.getItem('chooseLater') === 'true'){
    //   this.isShow = false
    // }else{
    //   this.isShow = true
    // }
  }
  setTheme(type:any,value?:any){
  if(value.isAvailable){
       if(type === 'use'){
        this.saveFn(value)
    //   localStorage.setItem('setTheme','true')
    //   if(!localStorage.getItem('chooseLater') && localStorage.getItem('chooseLater') !== 'true'){
    //     localStorage.setItem('goto','/#/app/online-store'),
    //     window.location.reload()
    //     // this._userService.autologin() 
    //  }else{
    //   this._router.navigate(['app/online-store'])
    // }
  }else if(type === 'preview'){
    // if(localStorage.getItem('chooseLater') === 'true'){
    //   this._router.navigate(['preview'])
    // }else {
      this._businessSetup.selectedTheme = value
      this._router.navigate(['theme-preview'])
    // }
  }
  //  else if(type === 'later'){
  //   // this._businessSetup.selectedTheme = null
  //   localStorage.setItem('setTheme','false')
  //   localStorage.setItem('chooseLater','true')
  //   this._router.navigate(['/'])
  // }
}
}

  getThemeConfiguration(){
      try{
        this._abd.showSpinner()
        this._businessSetup.getThemeConfigSettings().subscribe(res=>{
          if(res.success){
            this.themeList = res.data
           this._abd.hideSpinner()
          }
        })
      }
      catch{
        this._abd.hideSpinner()
      }
    }
    saveFn(currentTemplate?:any){
      let obj ={
          id:currentTemplate?.id,
          templateID:currentTemplate?.templateID,
          webStoreHost:currentTemplate?.webStoreHost,
          demoUrl:currentTemplate?.demoUrl,
          isDefault:true,
          isAvailable:currentTemplate?.isAvailable,
          imageUrl:currentTemplate?.imageUrl,
          isSelectedLater:currentTemplate?.isSelectedLater,
          createdDate:currentTemplate?.createdDate,
      }   
      try{
        this._abd.showSpinner()
        this._businessSetup.saveThemeInformation(obj).subscribe(res=>{
          if(res.success){
            this._businessSetup.getThemeConfigSettings().subscribe((res)=>{
              if(res.success){
             this.craeteDefaultLocation()
              this._globals.webStoreConfiguration = res.data;
              this._router.navigate(['/'])
             
              }
            })
            this._connMessageService.showToastMessage(`Theme Configuration saved successfully!`, 'success')
          }
        })
      }
      catch{
        this._connMessageService.showToastMessage(`Error saving Theme settings!`, 'error')
        this._abd.hideSpinner()
      }
    
}
  craeteDefaultLocation() {
   let obj = {
      LocationName: "Default Location",
      ManageInventory: true, //this._locationService.activeItem.manageInventory,
      Phone: this._globals.userInfo?.phone, //this._locationService.activeItem.phone.replace(/\D/g,''),
      Address: null,
      City: null,
      State: '',
      zip: null, //this._locationService.activeItem.zip,
      Country: "US",
      AllowShipping: true, //this._locationService.activeItem.allowShipping,
      AcceptOnlineOrders: true, //this._locationService.activeItem.acceptOnlineOrders
      // AcceptOnlineOrders: this.editform.get('acceptOnlineOrders').value, //this._locationService.activeItem.acceptOnlineOrders
    }
   
   
    try {
      
      this._locationService.createUpdateItem(obj, true).subscribe(res => {
       this._abd.loadInit()
        // this._connMessageService.showToastMessage(`Default Location saved successfully!`, 'success')
      })
    } catch (error) {
      // this._connMessageService.showToastMessage(`Error saving Default Location!`, 'error')
    }
  }
}

