    <div class="card border-round py-1" *ngIf="isLoadingSetup">
      <div class="flex justify-content-center  align-items-center p-4">
          <i class="pi pi-spin pi-spinner px-1 spin-color" style="font-size: xxx-large;"> </i>
      </div>
    </div>
 <div class="my-1" *ngIf="_abd.isSetupGuide && !_abd.isAdmin">
    <div class="card border-round py-1">
        <div class="flex flex-column w-full ">
           <div class="flex align-items-center justify-content-between cursor-pointer" (click)="toggleTopPanel()">
            <span class="font-semibold  text-xl text-muted py-1 pl-0">
                Setup Guide
               </span>
               <i class="pi  p-3 border-round " [ngClass]="isTopPanelOpen ?'pi-chevron-up':'pi-chevron-down' " ></i>
           </div>
               <span class="flex">
                <span class="w-12rem pr-3 pb-4 flex justify-content-end">{{outOf ? outOf : 0 }} of 4 tasks completed</span> 
                <span class="progressbar-width pl-0"><p-progressBar [value]="proressBar" [style]="{'height': '6px','margin-top':'6px','display':'flex','border-radius': '10px'}"></p-progressBar>
                </span>
                <span class="w-3rem px-2 progressbar-persent font-semibold">{{proressBar }}%</span>
                 </span>
          </div>
          <div class="expanding-div" [@expandAnimation]="expandPanelState">
            <div class="flex flex-column w-full py-3 ">
                <div class="grid">
                    <div class="col-6">
                        <div class="flex align-items-center bg-blue-50 border-round-lg p-2" (click)="openConfigration(isConfigration.status)" [ngClass]="isConfigration?.status === false ? 'clickable' : 'cursor-pointer' ">
                            <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isConfigration?.img"> </i><img *ngIf="isConfigration?.img" [src]="isConfigration?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Set Business Configurations</p>
                    </div>
                    </div>
                    <!-- <div class="col-6" >
                        <div class="flex align-items-center bg-blue-50 border-round-lg p-2" (click)="openLocation(isLocation.status)" [ngClass]="isLocation?.status === false ? 'clickable' : ''">
                            <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isLocation?.img"> </i><img *ngIf="isLocation?.img" [src]="isLocation?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Add a Location</p>
                    </div>
                    </div> -->
                    <div class="col-6" >
                        <div class="flex align-items-center bg-blue-50 border-round-lg p-2" (click)="openInventory(isInventory.status)" [ngClass]="isInventory?.status === false ? 'clickable' : ''" >
                            <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isInventory?.img"> </i><img *ngIf="isInventory?.img" [src]="isInventory?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Add / Import Inventory</p>
                    </div>
                    </div>
                    <div class="col-6" >
                        <div class="flex align-items-center bg-blue-50 border-round-lg p-2 cursor-pointer" (click)="openShippingConfig(isShipping?.status)">
                            <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isShipping?.img"> </i><img *ngIf="isShipping?.img" [src]="isShipping?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Set Shipping Configuration</p>
                    </div>
                    </div>
                    <div class="col-6" >
                        <div class="flex align-items-center bg-blue-50 border-round-lg p-2 cursor-pointer" (click)="openPaymentProcessing(isPaymentProcessing?.status)" >
                            <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isPaymentProcessing?.img"> </i><img *ngIf="isPaymentProcessing?.img" [src]="isPaymentProcessing?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Set Payment Processing Configuration</p>
                    </div>
                    </div>
                 
                    <!-- <div class="col-6" >
                        <div class="flex align-items-center bg-blue-50 border-round-lg p-2" (click)="openCustomer(isCustomer.status)" [ngClass]="isCustomer?.status === false ? 'clickable' : ''">
                        <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isCustomer?.img"> </i> <img *ngIf="isCustomer?.img" [src]="isCustomer?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Add a Customer</p>
                    </div>
                    </div> -->
                    <!-- <div class="col-6" >
                        <div class="flex align-items-center cursor-pointer bg-blue-50 border-round-lg p-2" (click)="openPosAppDownload(isPosAppDownload.status)" [ngClass]="isPosAppDownload?.status === false ? 'clickable' : ''">
                            <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isPosAppDownload?.img"> </i><img *ngIf="isPosAppDownload?.img" [src]="isPosAppDownload?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Download POSAPP using QR Code and Create a register from POSAPP.</p>
                    </div> 
                    </div>-->
                    <!-- <div class="col-6" >
                        <div class="flex align-items-center bg-blue-50 border-round-lg p-2" >
                            <i class="pi pi-spin pi-spinner px-1 spin-color" *ngIf="!isInvoice?.img"> </i><img *ngIf="isInvoice?.img" [src]="isInvoice?.img" class="p-2" alt="Tick"> <p class="text-base font-medium ">Make a Sale using Point of Sale</p>
                    </div>
                    </div> -->
                  </div>
                
              </div>
        </div>
    </div>
</div> 
 <div class="timeselector">
    <p-overlayPanel #opd>
        <p-menu
            #cm
            [model]="items"
            (click)="opd.hide()"
            [style]="{ 'min-width': '200px', border: '0px' }"
        ></p-menu>
    </p-overlayPanel>
    <!-- <p-overlayPanel #inventoption>
        <p-menu
            #cm
            [model]="InVentoryActionitems"
            (click)="opd.hide()"
            [style]="{ 'min-width': '200px', border: '0px' }"
        ></p-menu>
    </p-overlayPanel> -->
</div>

<div
    style=" text-align: left"
    class="grid"
>
    <!-- <div class="xl:col-5 lg:col-5 col-12">
        <div class="card py-1 border-radius-none"  *ngIf="dataList?.productsCount < 1"             style="
        padding:1rem;
        border-radius: 5px;
    ">
            <div class="flex flex-row flex-wrap  card-container  ">
                <div class="flex   m-2 flex-grow-1 ">
                   
                        <div class="flex font-bold text-lg align-items-center">
                            <span>Looks like you haven't added any products yet.</span>
                            <p-button
                            style="
                                padding: 0px;
                                padding-left: 5px;
                                background: white;
                                padding-right: 5px;
                                padding-top: 5px;
                                padding-bottom: 5px;
                                color: #444;
                            "
                            (click)="inventoption.toggle($event)"
                        >
                            Start adding inventory <span class="pi pi-import"></span>
                        </p-button>
                        </div>
                </div>

            </div>
        </div>

     
    </div> -->
    <div class="xl:col-12 lg:col-12 col-12" style="text-align: right">
        <p-button
            class="p-button-sm"
            (onClick)="opd.toggle($event)"
            icon="pi pi-filter"
            [label]="selectedPeriod"
            iconPos="right"
        ></p-button>
    </div>
</div> 
<div class="grid db-main dashboard justify-content-between mb-2">


    <!-- Things To Know -->
 <div class="xl:col-4 lg:col-4 md:col-6 sm:col-12 m-0" *ngIf="!_abd.isAdmin">   
        <div class="card h-full border-round">      
            <ng-container *ngIf="videosTutorial.length === 0 && isVideosTutorialLoad">
            <div class="flex justify-content-center align-items-center">
       <i class="pi pi-spinner pi-spin" style="color:var(--brandColor);font-size: 20px;" ></i>   
   </div>
   </ng-container>
   <ng-container *ngIf="videosTutorial.length > 0">
  <div class="slider-container h-full">
        <div class="slider-content" [style.transform]="'translateX(' + slideOffset + 'px)'" *ngFor="let item of videosTutorial ">
            <ng-container *ngIf="item.order === activeOrder">      
            <div class="flex flex-column">
                <span class="font-semibold  text-xl text b-muted py-1">
                    {{item.title}}
                       </span>
                     <span class="font-semibold py-1">
                       {{ item.subtitle}}
                     </span>
                 <p class="py-1 my-0">
                {{ item.description}} 
                 </p>
                     <div class="video-container py-1">
<iframe width="100%" [src]="item.videoURL" title="{{_global.whiteLabel.wlsName}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>

           </div>
      </ng-container>
        </div>
      </div>
       <div class="flex justify-content-center align-items-center pt-1 pb-0">
            <span *ngFor="let item of videosTutorial" [ngClass]=" item.order === activeOrder ? 'active-slider' : 'inactive-slider' " (click)="activeSideBar(item.order)" ></span>
</div> 
   </ng-container>
     
 
</div>
</div>
<!-- /Things To Know -->
<div class="xl:col-8 lg:col-8 md:col-6 sm:col-12 grid m-0 p-0">
<!-- Net Sales -->
    <div class="xl:col-6 lg:col-6 col-12" *ngIf="!isHandheld()">
    <div class="card h-full">
        <div class="grid">
            <div class="col-12">
                <div class="charttile">
                    <h5 style="margin-bottom: 2px">
                        {{ dataList?.totalSales | currency }}
                        <div
                            class="badge"
                            [ngClass]="
                                saleWidgetmain?.SaleDifference >= 0
                                    ? 'badge-sucess'
                                    : 'badge-danger'
                            "
                        >
                            <i
                                class="pi"
                                [ngClass]="
                                    saleWidgetmain?.SaleDifference >= 0
                                        ? 'pi-arrow-up '
                                        : 'pi-arrow-down'
                                "
                            ></i>
                            <span
                                *ngIf="
                                    saleWidgetmain?.previousTotalSales > 0
                                "
                            >
                                {{
                                    saleWidgetmain?.SaleDifferencepercent
                                        | number : "1.1-1"
                                }}
                                %
                            </span>
                        </div>
                    </h5>

                    <span class="text-muted">Net Sales</span>
                </div>
            </div>
            <div class="col-7">
                <br />
                <table>
                    <tr>
                        <td>
                            <span
                                class="point"
                                style="background: #ff6384"
                            ></span
                            >In-Store
                        </td>
                        <td style="text-align: right; min-width: 50px">
                            {{ dataList?.totalOfflineSales | currency }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span
                                class="point"
                                style="background: #36a2eb"
                            ></span
                            >Online
                        </td>
                        <td style="text-align: right; min-width: 50px">
                            {{ dataList?.totalOnlineSales | currency }}
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <p-chart
                    type="doughnut"
                    [data]="salesWidgetData"
                    [options]="chartOptions"
                    width="100%"
                    [style]="{ height: '100px' }"
                ></p-chart>
            </div>
        </div>
    </div>
</div>
<!-- /Net Sales -->
<!-- Total Orders -->
<div class="xl:col-6 lg:col-6 col-12" *ngIf="!isHandheld()">
    <div class="card h-full">
        <div class="grid">
            <div class="col-12">
                <div class="charttile">
                    <h5 style="margin-bottom: 2px">
                        {{ dataList?.totalOrders }}
                        <div
                            class="badge"
                            [ngClass]="
                                saleWidgetmain?.SaleDifference >= 0
                                    ? 'badge-sucess'
                                    : 'badge-danger'
                            "
                        >
                            <i
                                class="pi"
                                [ngClass]="
                                    saleWidgetmain?.SaleDifference >= 0
                                        ? 'pi-arrow-up '
                                        : 'pi-arrow-down'
                                "
                            ></i>
                            <span
                                *ngIf="
                                    saleWidgetmain?.previousTotalOrders > 0
                                "
                            >
                                {{
                                    saleWidgetmain?.OrderDifferencepercent
                                        | number : "1.1-1"
                                }}
                                %
                            </span>
                        </div>
                    </h5>

                    <span class="text-muted">Total Orders</span>
                </div>
            </div>
            <div class="col-7">
                <br />
                <table>
                    <tr>
                        <td>
                            <span
                                class="point"
                                style="background: #ff6384"
                            ></span
                            >In-Store
                        </td>
                        <td style="text-align: right; min-width: 50px">
                            {{ dataList?.totalOfflineOrders }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span
                                class="point"
                                style="background: #36a2eb"
                            ></span
                            >Online
                        </td>
                        <td style="text-align: right; min-width: 50px">
                            {{ dataList?.totalOnlineOrders }}
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <p-chart
                    type="doughnut"
                    [data]="ordersWidgetData"
                    [options]="chartOptions"
                    width="100%"
                    [style]="{ height: '100px' }"
                ></p-chart>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
<!-- /Total Orders -->
<!-- Top Productr width  > 991-->
<div class="xl:col-12 lg:col-12 col-12" *ngIf="!isHandheld() && isShowTopProduct">
      <div
            class="card h-full"
            style="
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
            "
        >
            <div class="">
                <div class="conn-grid-2x">
                    <div>
                        <span class="block font-medium mb-3 card-label fw-bold"
                            >Top Product</span
                        >

                        <div class="text-900 font-medium text-xl">
                            {{ dataList?.productOfMonth }}
                        </div>
                        <span class="text-green-500 font-medium"
                            >{{ dataList?.quantitySold }}
                        </span>

                        <span class="">quantity sold</span>
                        <br />
                        <!-- <div *ngIf="dataList?.productOfMonth.length<=15" class="text-900 font-medium text-xl">{{dataList?.productOfMonth}}</div>
                    <div *ngIf="dataList?.productOfMonth.length>15" class="text-900 font-medium text-xl">{{dataList?.productOfMonth | slice: 0:25 }}...</div> -->
                    </div>
                    <div class="">
                        <img
                            [src]="dataList?.productOfMonthPicture"
                            style="
                                border-radius: 100%;
                                max-width: 100%;
                                max-height: 145px;
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
</div>
<!-- /Top Product -->

</div>
<!-- Top Productr width  < 991-->
<div class="xl:col-12 lg:col-12 col-12 pb-3" *ngIf="!isHandheld() && !isShowTopProduct">
    <div
          class="card"
          style="
              background-size: contain;
              background-repeat: no-repeat;
              background-position: right;
          "
      >
          <div class="">
              <div class="conn-grid-2x">
                  <div>
                      <span class="block font-medium mb-3 card-label fw-bold"
                          >Top Product</span
                      >

                      <div class="text-900 font-medium text-xl">
                          {{ dataList?.productOfMonth }}
                      </div>
                      <span class="text-green-500 font-medium"
                          >{{ dataList?.quantitySold }}
                      </span>

                      <span class="">quantity sold</span>
                      <br />
                      <!-- <div *ngIf="dataList?.productOfMonth.length<=15" class="text-900 font-medium text-xl">{{dataList?.productOfMonth}}</div>
                  <div *ngIf="dataList?.productOfMonth.length>15" class="text-900 font-medium text-xl">{{dataList?.productOfMonth | slice: 0:25 }}...</div> -->
                  </div>
                  <div class="">
                      <img
                          [src]="dataList?.productOfMonthPicture"
                          style="
                              border-radius: 100%;
                              max-width: 100%;
                              max-height: 145px;
                          "
                      />
                  </div>
              </div>
          </div>
      </div>
</div>
<!-- /Top Product -->
</div>

<p-sidebar [(visible)]="showMainFilterPanel" position="right">
    <div [style]="{ 'min-width': '100%', 'margin-top': '30px' }">
        <div class="p-inputgroup">
            <!-- <div class="p-float-label">
                <p-calendar
                    
                    selectionMode="range"
                    [readonlyInput]="false"
                    inputId="multiple"
                    baseZIndex="0"
                    appendTo="body"
                    [style]="{ 'min-width': '100%' }"
                ></p-calendar>
                <label for="multiselect">Date Range</label>
            </div> -->
            <!-- <div class="p-float-label">
                {{selectedPeriod}}
            </div> -->

            <!-- <button
                (click)="opd.toggle($event)"
                type="button"
                pButton
                pRipple
                icon="pi pi-angle-down"
                styleClass="p-button-warn"
            > {{selectedPeriod}}</button> -->
        </div>
    </div>
</p-sidebar>

<div class="grid db-main dashboard">
    <!-- <div class="xl:col-4 lg:col-4 col-12" *ngIf="!isHandheld()">
        <div class="card">
            <div class="grid">
                <div class="col-12">
                    <div class="charttile">
                        <h5 style="margin-bottom: 2px">
                            {{ dataList?.totalSales | currency }}
                            <div
                                class="badge"
                                [ngClass]="
                                    saleWidgetmain?.SaleDifference >= 0
                                        ? 'badge-sucess'
                                        : 'badge-danger'
                                "
                            >
                                <i
                                    class="pi"
                                    [ngClass]="
                                        saleWidgetmain?.SaleDifference >= 0
                                            ? 'pi-arrow-up '
                                            : 'pi-arrow-down'
                                    "
                                ></i>
                                <span
                                    *ngIf="
                                        saleWidgetmain?.previousTotalSales > 0
                                    "
                                >
                                    {{
                                        saleWidgetmain?.SaleDifferencepercent
                                            | number : "1.1-1"
                                    }}
                                    %
                                </span>
                            </div>
                        </h5>

                        <span class="text-muted">Net Sales</span>
                    </div>
                </div>
                <div class="col-7">
                    <br />
                    <table>
                        <tr>
                            <td>
                                <span
                                    class="point"
                                    style="background: #ff6384"
                                ></span
                                >In-Store
                            </td>
                            <td style="text-align: right; min-width: 50px">
                                {{ dataList?.totalOfflineSales | currency }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span
                                    class="point"
                                    style="background: #36a2eb"
                                ></span
                                >Online
                            </td>
                            <td style="text-align: right; min-width: 50px">
                                {{ dataList?.totalOnlineSales | currency }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <p-chart
                        type="doughnut"
                        [data]="salesWidgetData"
                        [options]="chartOptions"
                        width="100%"
                        [style]="{ height: '100px' }"
                    ></p-chart>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="xl:col-4 lg:col-4 col-12" *ngIf="!isHandheld()">
        <div class="card">
            <div class="grid">
                <div class="col-12">
                    <div class="charttile">
                        <h5 style="margin-bottom: 2px">
                            {{ dataList?.totalOrders }}
                            <div
                                class="badge"
                                [ngClass]="
                                    saleWidgetmain?.SaleDifference >= 0
                                        ? 'badge-sucess'
                                        : 'badge-danger'
                                "
                            >
                                <i
                                    class="pi"
                                    [ngClass]="
                                        saleWidgetmain?.SaleDifference >= 0
                                            ? 'pi-arrow-up '
                                            : 'pi-arrow-down'
                                    "
                                ></i>
                                <span
                                    *ngIf="
                                        saleWidgetmain?.previousTotalOrders > 0
                                    "
                                >
                                    {{
                                        saleWidgetmain?.OrderDifferencepercent
                                            | number : "1.1-1"
                                    }}
                                    %
                                </span>
                            </div>
                        </h5>

                        <span class="text-muted">Total Orders</span>
                    </div>
                </div>
                <div class="col-7">
                    <br />
                    <table>
                        <tr>
                            <td>
                                <span
                                    class="point"
                                    style="background: #ff6384"
                                ></span
                                >In-Store
                            </td>
                            <td style="text-align: right; min-width: 50px">
                                {{ dataList?.totalOfflineOrders }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span
                                    class="point"
                                    style="background: #36a2eb"
                                ></span
                                >Online
                            </td>
                            <td style="text-align: right; min-width: 50px">
                                {{ dataList?.totalOnlineOrders }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <p-chart
                        type="doughnut"
                        [data]="ordersWidgetData"
                        [options]="chartOptions"
                        width="100%"
                        [style]="{ height: '100px' }"
                    ></p-chart>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="xl:col-3 lg:col-3 col-6">
        <div class="card ">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block  font-medium mb-3 card-label fw-bold">Online Orders</span>
                    <div class="text-900 font-medium text-xl">
                        {{ dataList?.totalOnlineOrders }}
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center  "
                    [ngStyle]="{ width: '2.5rem', height: '2.5rem' }">

                    <i class="pi pi-shopping-cart text-brandColor-700 text-xl text-widget-icon"
                        style="background: var(--bs-active-danger);"></i>
                </div>
            </div>
            
        </div>
    </div> -->
    <!-- <div class="xl:col-3 lg:col-3 col-6">
        <div class="card ">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block  font-medium mb-3 card-label fw-bold">Online Sales</span>
                    <div class="text-900 font-medium text-xl">
                        $ 0
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center  "
                    [ngStyle]="{ width: '2.5rem', height: '2.5rem' }">

                    <i class="pi pi-dollar text-brandColor-700 text-xl text-widget-icon"></i>
                </div>
            </div>
         
        </div>
    </div>
    <div class="xl:col-3 lg:col-3 col-6">
        <div class="card ">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block  font-medium mb-3 card-label fw-bold">In-Store Orders</span>
                    <div class="text-900 font-medium text-xl">
                        {{ dataList?.totalOfflineOrders }}
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center  "
                    [ngStyle]="{ width: '2.5rem', height: '2.5rem' }">

                    <i class="pi pi-shopping-cart text-brandColor-700 text-xl text-widget-icon"
                        style="    background: var(--green-500);"></i>
                </div>
            </div>
          
        </div>
    </div>
    <div class="xl:col-3 lg:col-3 col-6">
        <div class="card ">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block  font-medium mb-3 card-label fw-bold">In-Store Sales</span>
                    <div class="text-900 font-medium text-xl">
                        $ 0
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center  "
                    [ngStyle]="{ width: '2.5rem', height: '2.5rem' }">

                    <i class="pi pi-dollar text-brandColor-700 text-xl text-widget-icon"
                        style="background: var(--yellow-500);"></i>
                </div>
            </div>
          
        </div>
    </div>
    <div class="xl:col-3 lg:col-4 col-6">
        <div class="card ">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block  font-medium mb-3 card-label fw-bold">Sales
                    </span>
                 
                    <div class="text-900 font-medium text-xl">
                        ${{ dataList?.totalSales }}
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
                    [ngStyle]="{ width: '2.5rem', height: '2.5rem' }">
                    <i class="pi pi-map-marker text-orange-500 text-xl text-widget-icon"></i>


                </div>
            </div>
            <span class="text-green-500 font-medium">{{
                dataList?.totalSales/(dataList?.totalOrders==0?1:dataList?.totalOrders) | currency}}
            </span>
            <span class="">Average Ticket</span>
        </div>
    </div> -->
    <!-- <div class="xl:col-3 lg:col-4 col-6">
        <div class="card ">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block  font-medium mb-3 card-label fw-bold">Customers</span>
                    <div class="text-900 font-medium text-xl">
                        {{ dataList?.totalCustomers }}
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
                    [ngStyle]="{ width: '2.5rem', height: '2.5rem' }">
                    <i class="pi pi-users text-cyan-500 text-xl text-widget-icon"
                        style="background-color: var(--bs-danger);"></i>
                </div>
            </div><br>
      
        </div>
    </div> -->

    <!-- <div class="xl:col-4 lg:col-4 col-12" *ngIf="!isHandheld()">
        <div
            class="card"
            style="
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
            "
        >
            <div class="">
                <div class="conn-grid-2x">
                    <div>
                        <span class="block font-medium mb-3 card-label fw-bold"
                            >Top Product</span
                        >

                        <div class="text-900 font-medium text-xl">
                            {{ dataList?.productOfMonth }}
                        </div>
                        <span class="text-green-500 font-medium"
                            >{{ dataList?.quantitySold }}
                        </span>

                        <span class="">quantity sold</span>
                        <br /> -->
                        <!-- <div *ngIf="dataList?.productOfMonth.length<=15" class="text-900 font-medium text-xl">{{dataList?.productOfMonth}}</div>
                    <div *ngIf="dataList?.productOfMonth.length>15" class="text-900 font-medium text-xl">{{dataList?.productOfMonth | slice: 0:25 }}...</div> -->
                    <!-- </div>
                    <div class="">
                        <img
                            [src]="dataList?.productOfMonthPicture"
                            style="
                                border-radius: 100%;
                                max-width: 100%;
                                max-height: 145px;
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="xl:col-12 lg:col-12 col-12" *ngIf="dailysales && !isHandheld()">
        <div class="card">
            <div class="grid">
                <div class="col-12">
                    <div class="charttile">
                        <h5 style="margin-bottom: 2px">
                            {{ dailyAvgSale | currency }}
                        </h5>

                        <span class="text-muted">Average Daily Sale</span>
                    </div>
                </div>

                <div class="col-12">
                    <p-chart
                        type="bar"
                        height="180px"
                        [data]="dailysales"
                        [options]="dailystackedOptions"
                    >
                    </p-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-6 lg:col-6 col-12" *ngIf="topSellingProducts.length > 0">
        <div class="card">
            <span class="block font-medium mb-3 card-label fw-bold"
                >Best Selling Products</span
            >

            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="topSellingProducts"
                styleClass="p-datatable-striped"
                [paginator]="true"
                [rows]="5"
                responsiveLayout="scroll"
                showLoader="true"
                rowHover="true"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th style="text-align: right">Qty Sold</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td style="width: 50px; min-width: 5rem">
                            <div
                                *ngIf="product.image !== ''"
                                class="conn-flex-image-badge"
                                style="background-image:url({{product.image}})"
                            ></div>
                            <span
                                *ngIf="product.image === ''"
                                class="conn-flex-image-first-letter"
                            >
                                {{ product.productName | slice : 0 : 2 }}</span
                            >
                            <!-- <img src="{{
                                    product.image !== ''
                                        ? product.image
                                        : '/assets/images/default_product.png'
                                }}" class="" alt="{{ product.productName }}" width="50" /> -->
                        </td>
                        <td>
                            {{ product.productName }}
                        </td>
                        <td style="text-align: right; font-weight: 700">
                            {{ product.soldQuantity }}
                        </td>
                        <!-- <td style="width: 15%">
                            <button
                                pButton
                                pRipple
                                type="button"
                                icon="pi pi-search"
                                class="p-button p-component p-button-text p-button-icon-only"
                            ></button>
                        </td> -->
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="xl:col-6 lg:col-6 col-12" *ngIf="lowStockProducts.length > 0">
        <div class="card" [style]="{ 'min-width': '100%' }">
            <span class="block font-medium mb-3 card-label fw-bold"
                >Low Stock Products</span
            >

            <!-- [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="lowStockProducts"
                [paginator]="true"
                [rows]="5"
                responsiveLayout="scroll"
                showLoader="true"
                rowHover="true" -->

            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="lowStockProducts"
                [paginator]="true"
                [rows]="5"
                responsiveLayout="scroll"
                showLoader="true"
                rowHover="true"
                selectionMode="single"
                [(selection)]="lowStockProductSelectedRow"
                dataKey="itemSKUId"
                (onRowSelect)="onRowSelectLowStockProduct($event)"
                (onRowUnselect)="onRowUnselectLowStockProduct($event)"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th style="text-align: right">Qty</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr [pSelectableRow]="product">
                        <td style="width: 50px; min-width: 5rem">
                            <div
                                *ngIf="product.image !== ''"
                                class="conn-flex-image-badge"
                                style="background-image:url({{product.image}})"
                            ></div>
                            <span
                                *ngIf="product.image === ''"
                                class="conn-flex-image-first-letter"
                            >
                                {{ product.productName | slice : 0 : 2 }}</span
                            >
                            <!-- <img src="{{
                            product.image !== ''
                                        ? product.image
                                        : '/assets/images/default_product.png'
                                }}" class="" alt="{{ product.productName }}" width="50" /> -->
                        </td>
                        <td>
                            {{ product.productName }}
                        </td>
                        <td style="text-align: right; font-weight: 700">
                            {{ product.quantity }}
                        </td>
                        <!-- <td style="width: 15%">
                            <button
                                pButton
                                pRipple
                                type="button"
                                icon="pi pi-search"
                                class="p-button p-component p-button-text p-button-icon-only"
                            ></button>
                        </td> -->
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="xl:col-6 lg:col-6 col-12" *ngIf="timeTrackingReport.length > 0">
    <div *ngIf="timeTrackingReport.length > 0">
        <div class="card" [style]="{ 'width': '100%' }">
           <div class="flex align-items-center justify-content-between">
                <span class="block font-medium mb-3 card-label fw-bold"
                >Time Tracking Report</span
            >
            <div class="flex align-items-center pb-3">
                <div class="p-float-label">
                    <p-dropdown
                        [style]="{
                            height: '46px',
                            width: '100%',
                            'min-width': '120px',
                            'max-width': '160px'
                        }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="timeZoneList"
                        [formControl]="selectedTimeZone"
                        optionLabel="label"
                        [filter]="true"
                        placeholder=" "
                    >
                    </p-dropdown>
                    <label >Time Zone</label>
                </div> 
                <span class="block font-medium mb-3 card-label fw-bold"
                >
                <i class="pi pl-2" (click)="refreshTiemTrackingList()"  [ngClass]="{ 'pi-spinner pi-spin': isTimeTrackingRefresh,'pi-refresh': !isTimeTrackingRefresh}" style="font-size: 1.5rem;color:var(--brandColor)"></i>
                </span>
            </div>
               
        
           </div>
         

            <p-table
                [autoLayout]="true"
                [tableStyle]="{ width: 'max-content' }"
                [value]="timeTrackingReport"
                [paginator]="true"
                [rows]="5"
                responsiveLayout="scroll"
                showLoader="true"
                rowHover="true"
                selectionMode="single"
                 dataKey="itemSKUId"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>CLock-In</th>
                        <th>Clock-Out</th>
                        <th>Total Hours</th>
                        <!-- <th >Under Time</th> -->
                        <th >Status</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-clock>
                    <tr>
                        <td class="py-3">
                           
                                {{ clock?.userName }}
      
                        </td>
                        <td>
                            {{ clock?.clockInSummary }}
                        </td>
                        <td>
                            {{ clock?.clockOutSummary }}
                        </td>
                        <!-- <td>
                            {{ clock.overtime }}
                        </td> -->
                        <td >
                            {{ clock?.ftime !== undefined ? clock?.ftime :"--"}}
                        </td>
                        <td >
                            {{ clock?.currentStatus }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="grid" style="margin-top: 30px"></div>

    <!-- <div class="col-12 xl:col-6">
        <div class="card">
            <h5>Sales Overview</h5>
            <p-chart
                type="line"
                [data]="chartData"
                [options]="chartOptions"
            ></p-chart>
        </div>

        <div class="card">
            <div class="flex align-items-center justify-content-between mb-4">
                <h5>Notifications</h5>
                <div>
                    <button
                        pButton
                        type="button"
                        icon="pi pi-ellipsis-v"
                        class="p-button-rounded p-button-text p-button-plain"
                        (click)="menu.toggle($event)"
                    ></button>
                    <p-menu #menu [popup]="true" [model]="items"></p-menu>
                </div>
            </div>

            <span class="block text-600 font-medium mb-3">TODAY</span>
            <ul class="p-0 mx-0 mt-0 mb-4 list-none">
                <li
                    class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                    <div
                        class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                    >
                        <i class="pi pi-dollar text-xl text-blue-500"></i>
                    </div>
                    <span class="text-900 line-height-3"
                        >Richard Jones
                        <span class="text-700">
                            has purchased a blue t-shirt for
                            <span class="text-blue-500">79$</span></span
                        >
                    </span>
                </li>
                <li class="flex align-items-center py-2">
                    <div
                        class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0"
                    >
                        <i class="pi pi-download text-xl text-orange-500"></i>
                    </div>
                    <span class="text-700 line-height-3"
                        >Your request for withdrawal of
                        <span class="text-blue-500 font-medium">2500$</span> has
                        been initiated.</span
                    >
                </li>
            </ul>

            <span class="block text-600 font-medium mb-3">YESTERDAY</span>
            <ul class="p-0 m-0 list-none">
                <li
                    class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                    <div
                        class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                    >
                        <i class="pi pi-dollar text-xl text-blue-500"></i>
                    </div>
                    <span class="text-900 line-height-3"
                        >Keyser Wick
                        <span class="text-700">
                            has purchased a black jacket for
                            <span class="text-blue-500">59$</span></span
                        >
                    </span>
                </li>
                <li
                    class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                    <div
                        class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0"
                    >
                        <i class="pi pi-question text-xl text-pink-500"></i>
                    </div>
                    <span class="text-900 line-height-3"
                        >Jane Davis
                        <span class="text-700">
                            has posted a new questions about your product.</span
                        >
                    </span>
                </li>
            </ul>
        </div>

        <div
            class="px-4 py-5 shadow-2 flex flex-column md:flex-row md:align-items-center justify-content-between mb-3"
            [ngStyle]="{
                borderRadius: '1rem',
                background:
                    'linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)'
            }"
        >
            <div>
                <div class="text-blue-100 font-medium text-xl mt-2 mb-3">
                    TAKE THE NEXT STEP
                </div>
                <div class="text-white font-medium text-5xl">
                    Try PrimeBlocks
                </div>
            </div>
            <div class="mt-4 mr-auto md:mt-0 md:mr-0">
                <a
                    target="_blank"
                    href="https://www.primefaces.org/primeblocks-ng"
                    class="p-button font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised"
                >
                    Get Started
                </a>
            </div>
        </div>
    </div> -->
    </div>
  <p-sidebar
    *ngIf="gProductIdLowStockProduct"
    header="Item"
    [(visible)]="gProductIdLowStockProduct"
    [fullScreen]="true"
    class="productviewsidebar flex justify-content-end "
>
    <app-product-view-only
        [GID]="gProductIdLowStockProduct"
    ></app-product-view-only>
</p-sidebar>

<div class="trile-panel-sticky w-20rem border-round-2xl p-4 select-none" *ngIf="IsUserOnTrial">
    <div class="flex flex-row justify-content-between align-items-center cursor-pointer" (click)="togglePanel()">
        <span class="font-medium text-lg">
            Your free trial just started
        </span>
        <i  class="pi  p-2 border-round-md bg-color" [ngClass]="isPanelOpen ?'pi-chevron-up':'pi-chevron-down' " ></i>
    </div>
    <div class="expanding-div" [@expandAnimation]="expandState">
        <p class="py-1">Your free trial ends on <span class="font-semibold">{{dayLeftInFreeTrial | date:"mediumDate"}}</span>.</p>
        <p class="text-sm">
            Select a plan to keep using the extensive 
        features of {{_global.whiteLabel.wlName}}
        </p>
        <span class="select-plan flex cursor-pointer  justify-content-center align-items-center font-semibold border-round-3xl py-2 " (click)="navigateToSubscription()">
            Select a Plan
        </span>
    </div>

</div>
