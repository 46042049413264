import { UsStates } from 'src/app/_data/us-states';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ApiErrorsService } from './api-errors.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService extends ApiErrorsService {
  public static INIT_PAGE_NUMBER_FOR_SMALL_DATA: number = 0;

  public static whiteLabel: any = null
  public static userInfo: any = null
  public static userRoles: any = null
  public static webStoreConfiguration: any = null

  public static componentName = ''
  public static secondTitle = ''

  public static emailValidRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  public static emailValidatorString = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  public static passwordValidatorString = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/

  public static cacheFormNames: any = []
  public static cacheFormData: any = []

  public static usaStates = UsStates

  public static loginErrorMessage="";


  //------ PUBLIC SHOW/HIDE(s)
  public static showProductDelButton = false
  public static moveMainMenuToggleSwitch = false

  constructor() {
    super();
  }

  

  public static generatePhoneFormatUS(phone: any) {
    //normalize string and remove all unnecessary characters
    if (phone != "" && phone != undefined) {
      phone = phone.replace(/[^\d]/g, "");

      //check if number length equals to 10
      if (phone.length == 10) {
        //reformat and return phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
    }
    return null;
  }

  public static getUsaStateNameByAbb(abb: string) {
    const kount = GlobalService.usaStates.length
    let ret = ''
    for (let i = 0; i < kount; i++) {
      if (GlobalService.usaStates[i].abbreviation === abb) {
        ret = GlobalService.usaStates[i].name
        break
      }
    }
    return ret
  }

  public static generateSKU() {

    let code = GlobalService.userInfo.businessSetup[0].businessId.toString() + Math.floor(Date.now() / 1000).toString();
    return code.slice(0, -2);
  }

  public static sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  public static showErrorToast(data: any) {
    const errObj = this.getErrorObject(data)
    if (errObj) {
      // this.toast(errObj.message)
    }
  }

  public static getUserToken(): any {
    const model = JSON.parse(localStorage.getItem('user'));
    if (!model) return null
    return model.token
  }

  public static updateCacheFormData(formName: string, val: string) {
    let found = false;
    for (let i = 0; i < this.cacheFormNames.length; i++) {
      if (this.cacheFormNames[i] === formName) {
        found = true;
        this.cacheFormData[i] = val
        break;
      }
    }

    if (!found) {
      this.cacheFormNames.push(formName)
      this.cacheFormData.push(val)
    }
  }

  public static getCacheFormData(formName: string) {
    let found = false;
    for (let i = 0; i < this.cacheFormNames.length; i++) {
      if (this.cacheFormNames[i] === formName) {
        found = true;
        return this.cacheFormData[i]
      }
    }

    return false
  }

  public static setFormData(form: FormGroup, d: any) {
    for (const prop in d) {
      form.controls[prop].setValue(d[prop])
    }
  }

  public static getWlId() {
    try {
      const wlid = this.whiteLabel.id
      return wlid ? wlid : false
    } catch {
      return false
    }
  }

  //-------------------------------------
  //--       GLOBAL CONVERSION FUNCTIONS
  //-------------------------------------

  public static formatNumberWithCurrencySign(num:any) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)
  }

  public static roundNumbers(value, digits=2) {
    let ret = value.toFixed(digits)
    ret *=1
    return ret
  }

  //-------------------------------------
  //--       GLOBAL VALIDATIONS
  //-------------------------------------

  public static validateZipCode(zipCode: string) {
    if (/^[0-9]{5}$/.test(zipCode)) return (true)
    else return (false)
  }

  public static isValidEmail(value: string) {
    value = value.toLowerCase().trim()
    if (value.match(this.emailValidRegex)) return true;
    else return false;
  }

  public static validateNonZeroNumber(control: AbstractControl) {
    
    if(isNaN(control.value) || control.value == 'undefined') {
      return { nonzerovalidate: true };
    }
    if (control.value < 0) {
      return { nonzerovalidate: true };
    }
    return false;
  }

  //-------------------------------------
  //--       GLOBAL EXPORT DATA
  //-------------------------------------
  public static selectProps(props: any) {
    return function (obj) {
      const newObj = {};
      props.forEach(name => {
        newObj[name] = obj[name];
      });

      return newObj;
    }
  }

  // public static makeCsvCols(header: any, field: any) {
  //   let ret: any = []
  //   for (let i = 0; i < field.length; i++) {
  //     let xx = header[0][i]
  //     ret.push({ field: field[i], header: header[0][i] })
  //   }
  //   return ret
  // }

  // public static saveAsExcelFile(buffer: any, fileName: string): void {
  //   let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //   let EXCEL_EXTENSION = '.xlsx';
  //   const data: Blob = new Blob([buffer], {
  //     type: EXCEL_TYPE
  //   });
  //   FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  // }

  // public static exportExcel(dataList: any, colheader: any, colfields: any, reportname: any) {
  //   let data = this.refineDataForExport(dataList, colfields);
  //   import("xlsx").then(xlsx => {
  //     const worksheet = xlsx.utils.json_to_sheet(data);
  //     xlsx.utils.sheet_add_aoa(worksheet, [colheader], { origin: "A1" });
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer: any = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array"
  //     });
  //     this.saveAsExcelFile(excelBuffer, reportname);
  //   });
  // }

  // public static exportPdf(datalist: any, colheader: any, colfields: any, reportname: any, printsummary: boolean = false) {
  //   let data = this.refineDataForExport(datalist, colfields, printsummary, true);
  //   // //console.log(data);
  //   const doc = new jsPDF('l', 'pt', 'a4');
  //   autoTable(doc, {
  //     head: colheader,
  //     body: data,
  //     didDrawPage: (dataArg) => {
  //       doc.setFontSize(12);
  //       doc.setTextColor(40);
  //       doc.text(reportname + ' (' +
  //         formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + ')',
  //         dataArg.settings.margin.top, 30);
  //     }
  //   });

  //   doc.save(reportname + ' (' +
  //     formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + ')' + '.pdf')
  // }

  // public static refineDataForExport(dataList: any, colflds: any, printsummary: boolean = false, printPDF: boolean = false) {
  //   let data = []
  //   let summary = []
  //   // //console.log(dataList);
  //   // //console.log(colflds);
  //   /*
  //     Loop through the data from API and create a new array with only the columns we need to print
  //   */
  //   dataList.map(value=>{
  //     let row = [];
  //     colflds.map(cols=>{
  //       /*
  //         Format the date and hour variables to be printed
  //       */
  //       if(cols == 'date'){
  //         row.push(formatDate(value[cols], 'MM/dd/yyyy', 'en-US'));
  //       }else if(cols == 'hour'){
  //         var suffix = value[cols] <= 12 ? "AM":"PM"
  //         row.push(((value[cols] + 11) % 12 + 1)+ suffix) ;
  //       }else if(dataList[0][cols] instanceof Object || dataList[0][cols] instanceof Array){
  //         row.push("");
  //       } else if(cols == 'isActive'){
  //         row.push(value[cols] ? 'Active' : 'Inactive');
  //       } else if(printPDF){
  //         if(cols == 'value' || cols =='cash' || cols == 'creditCard' || cols == 'tax' || cols == 'grandTotal' || cols == 'subTotal' || cols == 'discount' || cols == 'sales' || cols == 'shippingAmount' || cols == 'shippingTax' || cols == 'salesTax' || cols == 'totalSales' || cols == 'totalCost' || cols == 'totalSale' || cols == 'totalProfit' || cols == 'taxableSales' || cols == 'nonTaxableSales' || cols == 'creditCardTotal' || cols == 'returnsTotal' || cols == 'voidsTotal' || cols == 'feesTotal' || cols == 'taxAmount' || cols == 'otherChargesAmount' || cols == 'invoiceTotal'){
  //           row.push('$ '+ value[cols].toFixed(2));
  //         } else if(cols == 'percent' || cols == 'taxRete'){
  //           row.push(value[cols].toFixed(2) + ' %');
  //         } else{
  //           row.push(value[cols]);
  //         }
  //       }
  //       else{
  //         row.push(value[cols]);
  //       }
  //     })
  //     data.push(row);
  //   })
  //   /*
  //     Code ends here
  //   */

  //   /*
  //     Following Code executes only if printsummary is true
  //     1. The code will map the data to a new array with only the columns we need to print
  //   */
  //   if(printsummary){
  //     let row = []
  //     colflds.map(value =>{
  //       summary = [];
  //       if(typeof dataList[0][value] == 'string' || value == 'hour'){
  //         summary.push("*Total*");
  //       }
  //       else{
  //         summary = dataList.reduce((sum, item) => sum + item[value], 0).toFixed(2);
  //       }
  //       if(value == 'value' || value =='cash' || value == 'creditCard' || value == 'tax' || value == 'grandTotal' || value == 'subTotal' || value == 'discount' || value == 'sales' || value == 'shippingAmount' || value == 'shippingTax' || value == 'salesTax'  || value == 'totalSales' || value == 'totalCost' || value == 'totalSale' || value == 'totalProfit' || value == 'taxableSales' || value == 'nonTaxableSales' || value == 'creditCardTotal' || value == 'returnsTotal' || value == 'voidsTotal' || value == 'feesTotal' || value == 'taxAmount' || value == 'otherChargesAmount' || value == 'invoiceTotal'){
  //         row.push('$ '+summary);
  //       } else if(value == 'percent' || value == 'taxRete'){
  //         row.push(summary + ' %');
  //       }
  //       else{
  //         row.push(summary);
  //       }
  //     })
  //     data.push(row);
  //   }
  //   /*
  //     Code ends here
  //   */
  //   return data;
  // }

  public static getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
}
} //-- GlobalService
