<div class="conn-grid-main create-product">
    <div>
        <p-tabMenu #tab [model]="items" [activeItem]="activeItem" [scrollable]="true" (click)="onTabItemClick(tab)">
            <!-- <ng-template pTemplate="item" let-item>
                <div class="flex">
                    {{item.label}}
                </div>
            </ng-template> -->
        </p-tabMenu>

        <div *ngIf="activeItem.label === 'General'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">General</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-general [singupBusinessConfig]="false"></app-general>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Tax Rate'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Tax Rate</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-tax-setting [singupBusinessConfig]="false"></app-tax-setting>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Invoice Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Invoice Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-invoice-configuration [singupBusinessConfig]="false"></app-invoice-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Printing Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Printing Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-printing-configuration [singupBusinessConfig]="false"></app-printing-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Security Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Security Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-security-configuration [singupBusinessConfig]="false"></app-security-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Cashdrop Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Cashdrop Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-cashdrop-configuration [singupBusinessConfig]="false"></app-cashdrop-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Cashdrawer Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Cashdrawer Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-cashdrawer-configuration [singupBusinessConfig]="false"></app-cashdrawer-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Tender Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Tender Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-tender-configuration [singupBusinessConfig]="false"></app-tender-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Secondary Screen Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Secondary Screen Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-secondary-screen-configuration [singupBusinessConfig]="false"></app-secondary-screen-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Purchase Order'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Purchase Order</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-purchase-order [singupBusinessConfig]="false"></app-purchase-order>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Time Tracking Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Time Tracking Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-time-tracking-configuration [singupBusinessConfig]="false"></app-time-tracking-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Shipping Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Shipping Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-shipping-configuration [singupBusinessConfig]="false"></app-shipping-configuration>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Payment Processing'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Payment Processing</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-payment-processing [singupBusinessConfig]="false"></app-payment-processing>
                </div>
            </div>
        </div>


        <!--<div *ngIf="activeItem.label === 'Loyalty Program Configuration'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <h2 class="w-title card-title">Loyalty Program Configuration</h2>
                <div class="conn-grid general-setting-component-container">
                    <app-loyalty-program-configuration [singupBusinessConfig]="false"></app-loyalty-program-configuration>
                </div>
            </div>
        </div>-->

    </div>
</div>
