<div class="flex justify-content-between mx-5 py-4  align-content-center">

 <span class="back-btn m-1"  (click)="setTheme('back')">
    Back to Theme
 </span>
 
  <span>
      <h3> <strong>
          You are previewing theme
      </strong>
      
    </h3>
  </span>
    <span class="usetheme-btn m-1"  (click)="setTheme('usetheme',_businessSetup?.selectedTheme)">
        Use Theme
     </span>
    
</div>
<div class="flex  justify-content-center">
    <div class="col flex justify-content-center box mx-5" >
      <!-- <div class="innerImage" [style.background-image]="_businessSetup?.selectedTheme?.isAvailable === true ? 'url(' + 'https://temp-p7q3z6.lincsell.com/wp-content/uploads/2024/09/template_alpha-scaled.webp' + ')' : ''"> -->
      <div class="innerImage" [style.background-image]="_businessSetup?.selectedTheme?.isAvailable === true ? 'url(' + _businessSetup?.selectedTheme?.imageUrl + ')' : ''">

      </div>
    </div>

 
</div>