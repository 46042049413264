import { AfterViewChecked, ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss']
})
export class MenuLeftComponent implements OnInit, DoCheck {
  items: MenuItem[]
  isFooter:boolean = false;
  _globals = GlobalService

  constructor(
    public _abd: AppBaseDataService,
    private _router: Router,
    private _cd:ChangeDetectorRef,
  ) { }
  ngDoCheck(): void { 
    this._abd.isLinkActive('app/online-store')
  }

  ngOnInit(): void {
    this._abd.whichRoute = 'any'
    this._abd.initMenuList(false)
  }
  opnePosDownload(){
     this._abd.isDownloadPosApp = true;
  }
} 
