<div>
        <div>
            <div>
                
                <br />  
              <div class="mb-2">
                
    <label class="control-label"><strong>Location
        <span style="color: red; font-size: 1.25rem">*</span>
    </strong></label>
                <p-dropdown
                [style]="{width:'100%',margin:'5px 0px'}"
                [(ngModel)]="currentSelectedLocation"
                (onChange)="locationChanged($event)"
                class="p-dropdown-1"
                [options]="_abd.locations"
                optionLabel="locationName"
                appendTo="body"
            >
            </p-dropdown></div>
                <form
                    [formGroup]="timeTrackingForm"
                    (ngSubmit)="timeTrackingForm.valid && saveFn()"
                    autocomplete="off"
                >
          
                <div class="mb-4">
                    <p-inputSwitch [formControl]="$any(timeTrackingForm.controls)['active']" inputId="rememberme47" ></p-inputSwitch>
                    <!-- <p-inputSwitch [formControl]="$any(timeTrackingForm.controls)['active']" inputId="rememberme47" (onChange)="checkConfigration($event)"></p-inputSwitch> -->
                    <label class="control-label" for="rememberme47"><strong> Enable</strong></label>
                </div>
                <!-- <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(timeTrackingForm.controls)['active']"
                        inputId="rememberme47"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme47">Enable</label>
                </div> -->
                 <br/>
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(timeTrackingForm.controls)['forceCheckIn']"
                        inputId="rememberme7"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme7">Force Check-In from POSAPP</label>
                </div>
                <br/>
                <div class="field-checkbox" >
                    <p-checkbox
                        [formControl]="$any(timeTrackingForm.controls)['autoCheckInOnLogin']"
                        inputId="rememberme8"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme8">Auto Check-In on Login from POSAPP</label>
                </div>
                <br  />
                <div class="field-checkbox" >
                        <p-inputSwitch [formControl]="$any(timeTrackingForm.controls)['enableTotalWorkingHours']" inputId="rememberme45"></p-inputSwitch>
                        <label class="control-label" for="rememberme45"> Enable Total Working Hours Per Day</label>

                </div>
                <br  />
                <label class="control-label" *ngIf="f.enableTotalWorkingHours.value === true"
                    >Total Working Hours</label>
                <app-text-input
                *ngIf="f.enableTotalWorkingHours.value === true"
               [formControl]="$any(timeTrackingForm.controls)['totalWorkingHours']"
                    [label]="'Enter Total Working Hours'"
                    [name]="'Total Working Hours'" ></app-text-input>
                <br *ngIf="f.enableTotalWorkingHours.value === true" />

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(timeTrackingForm.controls)['autoCheckoutOnLogout']"
                        inputId="rememberme2"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme2">Auto Check-Out on Logout from POSAPP</label>
                </div>
                <br/>

                <!-- <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(timeTrackingForm.controls)['forceCheckoutBeforeLogout']"
                        inputId="rememberme3"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme3">Force Check-Out Before Logout</label>
                </div> 
                <br/>-->
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(timeTrackingForm.controls)['printTimeTrackingReportFromPOSAPP']"
                        inputId="rememberme3"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <!-- <label for="rememberme3">Print Time Tracking Report from POSAPP</label> -->
                    <label for="rememberme3">Print Check-In/Check-Out Slip from POSAPP</label>
                </div>
                <br/>
                    
    

              
                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!timeTrackingForm.valid"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
