import { UserService } from 'src/app/_services/user.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { AfterViewChecked, AfterViewInit, Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { usTimeZoneList } from 'src/app/_data/time-zones';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-theme-edit',
  templateUrl: './theme-edit.component.html',
  styleUrls: ['./theme-edit.component.scss']
})
export class ThemeEditComponent implements OnInit,AfterViewInit   {
  _global = GlobalService;
  @ViewChild('iframe') iframeElement!: ElementRef<HTMLIFrameElement>;;
  isLoaded:boolean = true; 
  webUrl:SafeResourceUrl;

  constructor(
    public _abd: AppBaseDataService,
    public _businessSetup: BusinessSetupService,
    public _userService: UserService,
    private _router: Router,
    private _connMessageService: ConnMessageService,
    private sanitizer: DomSanitizer
  ){}

  ngAfterViewInit() {
    // const iframeWindow = this.iframeElement.nativeElement.contentWindow;
    // if (iframeWindow) {
    //   iframeWindow.postMessage('Hello iframe', 'https://development.lincsell.com');
    // }
  }

 
  ngOnInit(): void {

   if(this._global.webStoreConfiguration !== null){
      this.webUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._global.webStoreConfiguration?.demoUrl)    
      }

    // this.webUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://development.lincsell.com/?auth_token=signmein`);
    // this.webUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://dev.lincsell.com/?auth_token=signmein`);
   
    // setTimeout(() => {
    //   this.isLoaded = true;
    // }, 500);
    // if(localStorage.getItem('chooseLater') == 'true' && localStorage.getItem('setTheme') === 'false'){
    //   this._router.navigate(['select'])
    // }else if(localStorage.getItem('chooseLater') == 'false'){
    //   this._router.navigate(['app/online-store'])

    // }
  }
  checkPage() {
    // if(localStorage.getItem('chooseLater') == 'true' && localStorage.getItem('setTheme') === 'false'){
    //   this._router.navigate(['select'])
    // }
  //   else if(localStorage.getItem('chooseLater') == 'false'){
  
  // }
  // if(localStorage.getItem('setTheme') === 'true' && !localStorage.getItem('chooseLater') && localStorage.getItem('chooseLater') !== 'true' ){
  //   setTimeout(() => {
  //     this._abd.showSpinner()
  //     const iframe = this.iframeElement.nativeElement as HTMLIFrameElement;
  //     iframe.onload = () => {
  //     this._abd.hideSpinner()
  //     };
  //   }, 1000);
  // }
  }


}
