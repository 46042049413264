    <div>
        <div>
            <div>
                <br />
                <form
                    [formGroup]="shippingConfigurationForm"
                     autocomplete="off"
                >

                    <div class="mb-4">
                        <p-inputSwitch [formControl]="$any(shippingConfigurationForm.controls)['isEnabled']" inputId="rememberme8"></p-inputSwitch>
                        <label class="control-label" for="rememberme8"><strong> Enable</strong></label>
                    </div>
                    <br/>

                    <div class="field-checkbox mx-4 px-4" *ngIf="f.isEnabled.value === true">
                        <p-checkbox
                            [formControl]="$any(shippingConfigurationForm.controls)['isEnableFlat']"
                            inputId="rememberme1"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme1">Enable Flat Rate</label>
                    </div>
                    <div class="flex flex-column mx-4 px-4" *ngIf="f.isEnabled.value === true && f.isEnableFlat.value === true">
                        <label class="control-label mb-2"><strong>Flat Rate ($)
                       </strong></label>
                       <app-text-input
                       [formControl]="$any(shippingConfigurationForm.controls)['rate']"
                       [label]=""
                       [name]="'Flat Rate ($)'"
                       [description]=""
                   ></app-text-input>
                    </div>
                    <br/>

                    <div class="field-checkbox mx-4 px-4" *ngIf="f.isEnabled.value === true">
                        <p-checkbox
                            [formControl]="$any(shippingConfigurationForm.controls)['storePickUp']"
                            inputId="rememberme2"
                            [binary]="true"
                            styleClass="mr-2"
                        ></p-checkbox>
                        <label for="rememberme2">Enable Store Pick Up</label>
                    </div>
                    <br/>

                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!shippingConfigurationForm.valid"
                                (click)="saveFn()"
                                ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
