<form [formGroup]="editform" (ngSubmit)="('')" autocomplete="off">
    <div class="grid">
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 10px"
                    ><strong
                        >SKU (Stock Keeping Unit)
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <app-text-input
                    formControlName="sku"
                    [label]=""
                    [name]="'SKU'"
                    (input)="_productServices.pts.sku = $event.target.value"
                    [description]="'Automatically generated if not entered'"
                ></app-text-input>
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 10px"
                    ><strong
                        >Barcode
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <app-text-input
                    formControlName="barcode"
                    [label]=""
                    [name]="'Bar Code'"
                    (input)="_productServices.pts.barcode = $event.target.value"
                    [description]="'Automatically generated if not entered'"
                    id="inventory-product-barcode"
                ></app-text-input>
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 10px"
                    ><strong
                        >Min Stock Level
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong></label
                >
                <br />
                <app-text-input
                    formControlName="minLevel"
                    [label]=""
                    [name]="'Minimum level'"
                    (input)="_productServices.pts.minLevel = $event.target.value"
                    [description]="'An email will be sent when this level reached'"
                    id="inventory-product-minLevel"
                ></app-text-input>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 10px"
                    ><strong
                        >Max Stock Level
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong></label
                >
                <br />
                <app-text-input
                    formControlName="maxLevel"
                    [label]=""
                    [name]="'Max level'"
                    (input)="_productServices.pts.maxLevel = $event.target.value"
                    [description]="'An email will be sent when this level reached'"
                    id="inventory-product-maxLevel"
                ></app-text-input>
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 10px"
                    ><strong
                        >Reorder Level
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong></label
                >
                <br />
                <app-text-input
                    formControlName="reorderLevel"
                    [label]=""
                    [name]="'Reorder Level'"
                    (input)="_productServices.pts.reorderLevel = $event.target.value"
                    [description]="'An email will be sent when this level reached'"
                    id="inventory-product-reorderLevel"
                ></app-text-input>
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 10px"
                    ><strong
                        >Minmum Order Quantity
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong></label
                >
                <br />
                <app-text-input
                    formControlName="minOrderQty"
                    [label]=""
                    [name]="'Minimum Order Quantity'"
                    (input)="_productServices.pts.minOrderQty = $event.target.value"
                    [description]="'An email will be sent when this level reached'"
                    id="inventory-product-minOrderQty"
                ></app-text-input>
            </div>
        </div>
        <!-- Auto PO------------->
        <!-- <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 10px"
                    ><strong
                        >Maximum Order Quantity
                        <span style="color: red; font-size: 1.25rem"></span>
                    </strong></label
                >
                <br />
                <app-text-input
                    formControlName="maxOrderQty"
                    [label]=""
                    [name]="'Maximum Order Quantity'"
                    (input)="_productServices.pts.maxOrderQty = $event.target.value"
                    [description]="'An email will be sent when this level reached'"
                    id="inventory-product-maxOrderQty"
                ></app-text-input>
            </div>
        </div>
    </div> -->
    <!-- /Auto PO------------>
    <!-- Auto PO -->
    <!-- <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <div>
                <div class="p-field-checkbox" style="margin-top: 10px">
                    <p-checkbox
                        [binary]="true"
                        [ngModelOptions]="{ standalone: true }"
                        value="Track Stock"
                        [(ngModel)]="_productServices.pts.autoPurchaseOrder"
                        inputId="ny"
                    ></p-checkbox>
                    <label for="ny" style="margin-left: 10px">Auto PO</label>
                </div>
            </div>
        </div> -->
        <!-- /Auto PO -->
    </div>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <div>
                <div class="p-field-checkbox" style="margin-top: 10px">
                    <p-checkbox
                        [binary]="true"
                        [ngModelOptions]="{ standalone: true }"
                        value="Track Stock"
                        [(ngModel)]="_productServices.isTrackStock"
                        inputId="ny"
                        [disabled]="_productServices.isViewOnly"
                    ></p-checkbox>
                    <label for="ny" style="margin-left: 10px">Track Stock</label>
                </div>
                <!-- <div class="p-field-checkbox" style="margin-top: 10px">
                    <p-checkbox
                        [binary]="true"
                        [ngModelOptions]="{ standalone: true }"
                        value="Sell Online"
                        [(ngModel)]="_productServices.isSellOnline"
                        inputId="la"
                    ></p-checkbox>
                    <label for="la" style="margin-left: 10px">Sell Online</label>
                </div> -->
            </div>
        </div>

    </div>
    <div class="grid" *ngIf="_productServices.isTrackStock === true">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 px-4 mx-4">
            <div>
                <div class="p-field-checkbox" style="margin-top: 10px">
                    <p-checkbox
                        [binary]="true"
                        [ngModelOptions]="{ standalone: true }"
                        value="Allow Negative Stock"
                        [(ngModel)]="_productServices.isAllowNegativeStock"
                        inputId="sf"
                        [disabled]="_productServices.isViewOnly"
                    ></p-checkbox>
                    <label for="sf" style="margin-left: 10px"
                        >Allow Negative Stock</label
                    >
                </div>
                <!-- <div class="p-field-checkbox" style="margin-top: 10px">
                    <p-checkbox
                        [binary]="true"
                        [ngModelOptions]="{ standalone: true }"
                        value="Sell Online"
                        [(ngModel)]="_productServices.isSellOnline"
                        inputId="la"
                    ></p-checkbox>
                    <label for="la" style="margin-left: 10px">Sell Online</label>
                </div> -->
            </div>
        </div>

    </div>
</form>
