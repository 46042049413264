<div class="fadeindown points-redemption-page animation-duration-250 flex">
    <div class="conn-card flex align-content-between w-full"
        *ngIf=" _globals.userInfo.businessSetup[0].enableLoyaltyReward">
        <div class="flex flex-wrap justify-content-between flex-column">
            <div class="font-bold text-2xl page-heading-color">
                Loyalty Program Configuration
            </div>
            <div class="text-lg text-bluegray-300 mt-1">
                Enable it if you want to run loyalty program for your business
            </div>
        </div>
        <div class="flex flex-wrap gap-2 my-2 flex-row">
            <p-inputSwitch
                [(ngModel)]="enableLoyaltyProgram"
                [ngModelOptions]=" {standalone: true}"
                inputId="enableLoyaltyProgram">
            </p-inputSwitch>
            <label class="control-label" for="enableLoyaltyProgram"><strong> Enable</strong></label>
        </div>
        <div class="flex w-full dotted-border-bottom box-shadow-none my-3"></div>
        <form [formGroup]="loyaltyConfiguration" (keydown.enter)="$event.preventDefault()" class="w-full"
            (ngSubmit)="saveInformation()" autocomplete="off">
            <p-accordion>
                <p-accordionTab header="Points Accumulation" [disabled]="!enableLoyaltyProgram"
                    [selected]="showAccordion">
                    <div class="flex flex-column p-fluid w-6">
                        <div class="field">
                            <label htmlfor="InputDollarValue">Points Value <span class="text-red-500">*</span> </label>
                             <p-inputNumber
                                inputId="locale-us"
                                mode="decimal"
                                locale="en-US"
                                placeholder="Dollar Value"
                                [min]="0"
                                [max]="1000000"
                                formControlName="dollarValue"
                                [disabled]="!enableLoyaltyProgram"
                                [maxFractionDigits]="2">
                             </p-inputNumber>

                            <div class="text-sm text-bluegray-300 mt-1">
                                Define value of each dollar to points i.e. $1 = X Points
                            </div>
                            <div *ngIf="(!loyaltyConfiguration.get('dollarValue')?.valid
                                && (loyaltyConfiguration.get('dollarValue')?.dirty || loyaltyConfiguration.get('dollarValue')?.touched)
                            )
                            ">
                                 <div class="input-required mt-1"
                                     [hidden]="loyaltyConfiguration.get('dollarValue').value?.length == 0 &&
                                     !loyaltyConfiguration.get('dollarValue').errors.required">
                                     Please enter a Dollar Value
                                 </div>
                             </div>
                        </div>
                        <div class="field">
                            <label htmlfor="InputPointAccumulationThreshold">Minimum Purchase Required for points Accumulation <span
                                    class="text-red-500">*</span></label>
                            <div class="col-12 md:col-12">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">$</span>
                                    <p-inputNumber
                                        inputId="locale-us"
                                        mode="decimal"
                                        locale="en-US"
                                        placeholder="Point Accumulation Threshold"
                                        [min]="0"
                                        [max]="1000000"
                                        formControlName="pointsAccThreshold"
                                        [disabled]="!enableLoyaltyProgram"
                                        [maxFractionDigits]="2">
                                    </p-inputNumber>
                                </div>
                            </div>
                            <div class="text-sm text-bluegray-300 mt-1">
                                Minimum purchase needed for points accumulation i.e. X Dollars
                            </div>
                            <div *ngIf="(!loyaltyConfiguration.get('pointsAccThreshold')?.valid
                                && (loyaltyConfiguration.get('pointsAccThreshold')?.dirty || loyaltyConfiguration.get('pointsAccThreshold')?.touched)
                            )
                            ">
                                  <div class="input-required mt-1" [hidden]="loyaltyConfiguration.get('pointsAccThreshold').value?.length == 0 &&
                                     !loyaltyConfiguration.get('pointsAccThreshold').errors.required">
                                      Please enter a Point Accumulation Threshold
                                  </div>
                              </div>
                        </div>
                        <div class="field">
                            <label htmlfor="InputMaxPointsAllowed">Maximum Points Allowed <span
                                    class="text-red-500">*</span> </label>

                            <p-inputNumber
                                inputId="locale-us"
                                mode="decimal"
                                locale="en-US"
                                placeholder="Maximum Points Allowed"
                                [min]="0"
                                [max]="1000000"
                                [disabled]="!enableLoyaltyProgram"
                                formControlName="maxPointsAllowed"
                                [maxFractionDigits]="2">
                            </p-inputNumber>
                            <div class="text-sm text-bluegray-300 mt-1">
                                Maximum limit to points accumulation
                            </div>
                            <div *ngIf="(!loyaltyConfiguration.get('maxPointsAllowed')?.valid
                                && (loyaltyConfiguration.get('maxPointsAllowed')?.dirty || loyaltyConfiguration.get('maxPointsAllowed')?.touched)
                            )
                            ">
                                <div class="input-required mt-1" [hidden]="loyaltyConfiguration.get('maxPointsAllowed').value?.length == 0 &&
                                     !loyaltyConfiguration.get('maxPointsAllowed').errors.required">
                                    Please enter a Maximum Points Allowed
                                </div>
                            </div>
                        </div>
                        <div class="field w-5">
                            <label htmlfor="InputPointsExpiry">Points Expiry</label>
                            <p-dropdown
                                [options]="expiryDurationList"
                                [(ngModel)]="selectedExpiry"

                                [disabled]="!enableLoyaltyProgram"
                                [ngModelOptions]="{standalone: true}"
                                optionLabel="title">
                            </p-dropdown>
                        </div>
                        <div class="field flex w-full">
                            <label htmlfor="InputDiscountOnItem" class="col-12 mb-2 md:col-8 md:mb-0">
                                Allow points accumulation on discounted items
                            </label>
                            <div class="col-12 md:col-4">
                                <p-inputSwitch
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="InputDiscountOnItem"
                                    [disabled]="!enableLoyaltyProgram"
                                    inputId="InputDiscountOnItem">
                                </p-inputSwitch>
                                <label class="control-label" for="InputDiscountOnItem"><strong> Enable</strong></label>
                            </div>
                        </div>
                        <div class="field flex w-full">
                            <label htmlfor="InputDiscountOpenDepart" class="col-12 mb-2 md:col-8 md:mb-0">
                                Allow points accumulation on open department items
                            </label>
                            <div class="col-12 md:col-4">
                                <p-inputSwitch
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="InputDiscountOpenDepart"
                                    [disabled]="!enableLoyaltyProgram"
                                    inputId="InputDiscountOpenDepart">
                                </p-inputSwitch>
                                <label class="control-label" for="InputDiscountOpenDepart"><strong> Enable</strong></label>
                            </div>
                        </div>

                    </div>
                </p-accordionTab>
                <p-accordionTab header="Points Redemption" [disabled]="!enableLoyaltyProgram">
                    <div class="flex points-redemption-page  flex-column p-fluid w-6">
                        <div class="field">
                            <label htmlfor="InputRewardDay">Reward Day & Time
                            </label>
                            <p-multiSelect
                                [options]="daysList"
                                [(ngModel)]="selectedDays"
                                [disabled]="!enableLoyaltyProgram"
                                [ngModelOptions]="{standalone: true}"
                                optionLabel="name">
                            </p-multiSelect>

                        </div>
                        <div class="field flex w-full">
                            <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-8 md:mb-0">
                                Cashier Alert
                            </label>
                            <div class="col-12 md:col-4">
                                <p-inputSwitch
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="InputCashierAlert"
                                    [disabled]="!enableLoyaltyProgram"
                                    inputId="InputCashierAlert">
                                </p-inputSwitch>
                                <label class="control-label" for="InputCashierAlert"><strong> Enable</strong></label>
                            </div>
                        </div>
                        <div class="card dotted-border-bottom box-shadow-none mb-5" [ngClass]="{'disabled-input': !InputCashierAlert}">
                            <div class="flex" *ngFor="let alertOption of cashierAlerts">
                                <div class="col-12 md:col-1">
                                    <p-checkbox
                                        [ngClass]="{'disabled-input': !InputCashierAlert}"
                                        [binary]="true"
                                        [disabled]="!enableLoyaltyProgram"
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="alertOption.active">
                                    </p-checkbox>
                                </div>
                                <label class="col-12 mb-2 md:col-11 md:mb-0">
                                    {{alertOption.title}}
                                </label>
                            </div>

                        </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Printing & Interaction" [disabled]="!enableLoyaltyProgram">
                    <div class="flex print-page flex-column p-fluid w-6">
                        <div class="field flex w-full">
                            <label htmlfor="InputPrintPointsOnReceipt" class="col-12 mb-2 md:col-8 md:mb-0">
                                Print points info on the receipt
                            </label>
                            <div class="col-12 md:col-4">
                                <p-inputSwitch
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="InputPrintPointsOnReceipt"
                                    [disabled]="!enableLoyaltyProgram"
                                    [disabled]="!enableLoyaltyProgram"
                                    inputId="InputPrintPointsOnReceipt">
                                </p-inputSwitch>
                                <label class="control-label" for="InputPrintPointsOnReceipt">
                                    <strong>Enable</strong>
                                </label>
                            </div>
                        </div>
                       <div class="card border-dotted-custom mb-5 disabled-input"
                            >
                            <div class="flex" *ngFor="let info of printInfoList">
                                <div class="col-12 md:col-1">
                                    <p-checkbox
                                        class="disabled-input"
                                        [binary]="true"
                                        [disabled]="!enableLoyaltyProgram"
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="InputPrintPointsOnReceipt">
                                    </p-checkbox>
                                </div>
                                <label class="col-12 mb-2 md:col-11 md:mb-0">
                                    {{info.title}}
                                </label>
                            </div>
                        </div>
                        <div class="field flex flex-column w-full">
                            <div class="flex">
                                <label htmlfor="InputDisplayPointsOnSC" class="col-12 mb-2 md:col-8 md:mb-0">
                                    Display points info on secondary screen
                                </label>
                                <div class="col-12 md:col-4">
                                    <p-inputSwitch
                                        [(ngModel)]="InputDisplayPointsOnSC"
                                        [disabled]="!enableLoyaltyProgram"

                                        [ngModelOptions]="{standalone: true}" inputId="InputDisplayPointsOnSC">
                                    </p-inputSwitch>
                                    <label class="control-label" for="InputDisplayPointsOnSC">
                                        <strong>Enable</strong>
                                    </label>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="col-12 md:col-12 text-sm text-bluegray-300 mt-1">
                                    This will show customer point goals & available perks on screen
                                </div>
                            </div>
                        </div>
                        <!--
                        <div class="field flex w-full">
                            <label htmlfor="InputSMSNotification" class="col-12 mb-2 md:col-8 md:mb-0">
                                SMS notification to customers
                            </label>
                            <div class="col-12 md:col-4">
                                <p-inputSwitch
                                    [disabled]="!enableLoyaltyProgram"
                                    [ngModelOptions]="{standalone: true}"

                                    [(ngModel)]="InputSMSNotification"
                                    inputId="InputSMSNotification">
                                </p-inputSwitch>
                                <label class="control-label" for="InputSMSNotification">
                                    <strong>Enable</strong>
                                </label>
                            </div>
                        </div>

                        <div class="card dotted-border-bottom box-shadow-none mb-5"
                            [ngClass]="{'disabled-input': !InputSMSNotification}">
                            <div class="flex" *ngFor="let info of smsNotificationList">
                                <div class="col-12 md:col-1">
                                    <p-checkbox
                                        [ngClass]="{'disabled-input': !InputSMSNotification}"
                                        [binary]="true"
                                        [disabled]="!enableLoyaltyProgram"
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="info.active">
                                    </p-checkbox>

                                </div>
                                <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-11 md:mb-0">
                                    {{info.title}}
                                </label>
                            </div>
                        </div>
                        -->
                        <div class="field flex w-full">
                            <label htmlfor="InputEmailNotification" class="col-12 mb-2 md:col-8 md:mb-0">
                                Email notification to customers
                            </label>
                            <div class="col-12 md:col-4">
                                <p-inputSwitch
                                    [(ngModel)]="InputEmailNotification"
                                    [disabled]="!enableLoyaltyProgram"
                                    [ngModelOptions]="{standalone: true}"
                                    inputId="InputEmailNotification">
                                </p-inputSwitch>
                                <label class="control-label" for="InputEmailNotification">
                                    <strong>Enable</strong>
                                </label>
                            </div>
                        </div>
                        <div class="card dotted-border-bottom box-shadow-none mb-5"
                            [ngClass]="{'disabled-input': !InputEmailNotification}">
                            <div class="flex">
                                <div class="col-12 md:col-1">
                                    <p-checkbox
                                        [ngClass]="{'disabled-input': !InputEmailNotification}"
                                        [binary]="true"
                                        [disabled]="!enableLoyaltyProgram"
                                        [ngModelOptions]=" {standalone: true}"
                                        [(ngModel)]="sendEmailWhenPointsRedeemed">
                                    </p-checkbox>

                                </div>
                                <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-11 md:mb-0">
                                    Send Email when points are redeemed
                                </label>
                            </div>
                             <div class="flex flex-column" *ngIf="loyaltyObj.extraRewardInfoOnReceiptTags?.length > 0">

                                 <label class="control-label keyword-info py-2">
                                     Below are available keywords for use. Please type keyword between brackets ([]) in
                                     message editor for use.
                                     <small>e.g. [{{loyaltyObj.extraRewardInfoOnReceiptTags?.[0].title}}]</small><br>
                                 </label>
                                 <br>
                                 <div class="flex">
                                     <p-tag *ngFor="let tag of loyaltyObj.extraRewardInfoOnReceiptTags"
                                         styleClass="mr-2 my-1" severity="info" value="{{tag.title}}"></p-tag>
                                 </div>
                             </div>
                            <div class="flex"
                                [ngClass]="{'disabled-input':  !sendEmailWhenPointsRedeemed || !enableLoyaltyProgram}">
                                 <div class="field w-full my-3">
                                     <label htmlfor="InputMaxPointsAllowed">Subject </label>
                                     <input pinputtext="" id="InputMaxPointsAllowed" type="text"
                                         formControlName="subject" [disabled]="!sendEmailWhenPointsRedeemed"
                                         placeholder="Subject"
                                         class="p-inputtext p-component p-element">
                                 </div>
                            </div>

                            <div class="flex"
                                [ngClass]="{'disabled-input':  !sendEmailWhenPointsRedeemed || !enableLoyaltyProgram}">
                                <div class="field" >
                                    <label htmlfor="InputMaxPointsAllowed">Message </label>
                                    <p-editor formControlName="message" [style]="{'height':'320px','width':'100%'}">

                                    </p-editor>
                                </div>
                            </div>

                            <div class="flex">
                                <div class="col-12 md:col-2">
                                    <p-checkbox
                                        [ngModelOptions]=" {standalone: true}"
                                        [disabled]="!enableLoyaltyProgram"
                                        [ngClass]="{'disabled-input': !InputEmailNotification}"
                                        [binary]="true"
                                        [(ngModel)]="sendEmailWhenPointsAccumulated"></p-checkbox>

                                </div>
                                <label htmlfor="InputCashierAlert" class="col-12 mb-2 md:col-10 md:mb-0">
                                    Send Email when points are accumulated
                                </label>


                            </div>
                             <div class="flex flex-column" *ngIf="loyaltyObj.extraRewardInfoOnReceiptTags?.length > 0">

                                 <label class="control-label keyword-info py-2">
                                     Below are available keywords for use. Please type keyword between brackets ([]) in
                                     message editor for use.
                                     <small>e.g. [{{loyaltyObj.extraRewardInfoOnReceiptTags?.[0].title}}]</small><br>
                                 </label>
                                 <br>
                                 <div class="flex">
                                     <p-tag *ngFor="let tag of loyaltyObj.extraRewardInfoOnReceiptTags"
                                         styleClass="mr-2 my-1" severity="info" value="{{tag.title}}"></p-tag>
                                 </div>
                             </div>
                            <div class="flex"
                                [ngClass]="{'disabled-input':  !sendEmailWhenPointsAccumulated || !enableLoyaltyProgram}">
                                <div class="field w-full my-3">
                                    <label htmlfor="InputMaxPointsAllowed">Subject </label>
                                    <input pinputtext="" id="InputMaxPointsAllowed" type="text"
                                        formControlName="pointsAccumulateEmailSubject"
                                        [disabled]="!sendEmailWhenPointsAccumulated"
                                        placeholder="Subject"
                                        class="p-inputtext p-component p-element">
                                </div>
                            </div>

                            <div class="flex"
                                [ngClass]="{'disabled-input':  !sendEmailWhenPointsAccumulated || !enableLoyaltyProgram}">
                                <div class="field">
                                    <label htmlfor="InputMaxPointsAllowed">Message </label>
                                    <p-editor formControlName="pointsAccumulateEmailBody"
                                        [style]="{'height':'320px','width':'100%'}">

                                    </p-editor>
                                </div>
                            </div>
                        </div>

                        <div class="field flex w-full">
                             <label htmlfor="isExtraRewardInfoOnReceipt" class="col-12 mb-2 md:col-8 md:mb-0">
                                 Print reward program on receipt
                             </label>
                             <div class="col-12 md:col-4">
                                 <p-inputSwitch [(ngModel)]="isExtraRewardInfoOnReceipt" [disabled]="!enableLoyaltyProgram"
                                     [ngModelOptions]="{standalone: true}" inputId="isExtraRewardInfoOnReceipt">
                                 </p-inputSwitch>
                                 <label class="control-label" for="isExtraRewardInfoOnReceipt">
                                     <strong>Enable</strong>
                                 </label>
                             </div>
                        </div>
                         <div class="card dotted-border-bottom box-shadow-none mb-5"
                             [ngClass]="{'disabled-input': !isExtraRewardInfoOnReceipt}">


                             <div class="flex"
                                 [ngClass]="{'disabled-input':  !enableLoyaltyProgram}">
                                 <div class="field">
                                     <label htmlfor="InputMaxPointsAllowed">Print Text </label>
                                     <p-editor id="print_editor" formControlName="extraRewardInfoOnReceipt"
                                         [style]="{'height':'320px','width':'100%'}">

                                     </p-editor>
                                 </div>
                             </div>
                         </div>
                    </div>
                </p-accordionTab>
            </p-accordion>

            <div class="grid" style="margin-top: 10px">
                <div class="col" style="margin-top: 7px">
                    <div class="flex justify-content-start flex-wrap card-container green-container mt-3">
                        <div class="flex align-items-center justify-content-center">
                            <button [disabled]="!loyaltyConfiguration.valid" pButton pRipple
                                type="submit"
                                label="Save"></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="conn-card flex align-content-between w-full"
         *ngIf="!_globals.userInfo.businessSetup[0].enableLoyaltyReward">
          <div class="flex card-container blue-container overflow-hidden">

            <div
                  class="flex-grow-1 flex align-items-center justify-content-center text-3xl m-2 px-5 py-3
                  border-round">
                <i _ngcontent-ghs-c91="" class="pi pi-exclamation-triangle mx-2 text-3xl"></i> No access found
            </div>

          </div>
    </div>
</div>
