<div class="flex flex-column justify-content-center py-5 align-items-center">
    <h2>
        Choose Your Theme
    </h2>
    <p class="col-7 " style="text-align: center;">
        Site themes are perfect for a quick start. Choose the theme you like and change whatever you want, like colors, fonts, and images. Personalize the themes for your business, and your site is ready to publish. 
    </p>
</div>
<div class="flex  justify-content-center">

    <!-- <div class="col flex justify-content-center box mx-5" *ngFor="let theme of themeList"> -->




    <div *ngFor="let store of _globals.webStoreConfiguration" class="col flex justify-content-center box mx-5" [style.background-image]="'url(' + store?.imageUrl+ ')'">
    <!-- <div *ngFor="let store of _globals.webStoreConfiguration" class="col flex justify-content-center box mx-5" [style.background-image]="'url(' + 'https://temp-p7q3z6.lincsell.com/wp-content/uploads/2024/09/template_alpha-scaled.webp' + ')'"> -->
         <div class="w-6 flex justify-content-end flex-column mb-4" > 
         <span class="usetheme-btn m-1"    [ngClass]="store?.isAvailable === true ? 'usetheme-btn': 'disabled'"  (click)="setTheme('use',store)">
            Use Theme
         </span>
         <span class="m-1" [ngClass]="store?.isAvailable === true ? 'preview-btn': 'disabled'"   (click)="setTheme('preview',store)">
            Preview Theme
         </span>
        </div> </div>
        
    <!-- </div> -->
    <!-- <div class="col flex justify-content-center box mx-5">
       <div class="w-6 flex justify-content-between text-align-center flex-column mb-4" >
        <p>
            Will be avaliable in beta
        </p>
        <div>
              <button
            [disabled]="true"
            class="m-1 w-full"
            pButton
            type="button"
            label="Use Theme"
            (click)="setTheme('usetheme')"
         ></button>
         <button
         [disabled]="true"
         style="background-color: white;color:black;"
         class="m-1 w-full"
         pButton
         type="button"
         label="Preview Theme"
         (click)="setTheme('preview')"
      ></button>  
        </div>
         
        </div>
    </div> -->
    <!-- <div class="col flex justify-content-center box mx-5">
        <div class="w-6 flex justify-content-between text-align-center flex-column mb-4" >
            <p>
                Will be avaliable in beta
            </p>
            <div>
                  <button
                [disabled]="true"
                class="m-1 w-full"
                pButton
                type="button"
                label="Use Theme"
                (click)="setTheme('usetheme')"
             ></button>
             <button
             [disabled]="true"
             style="background-color: white;color:black;"
             class="m-1 w-full"
             pButton
             type="button"
             label="Preview Theme"
             (click)="setTheme('preview')"
          ></button>  
            </div>
    
        </div>
    </div> -->
 
</div>
<div class="flex justify-content-end align-content-center m-5 ">
<!-- <span class="later-btn m-1" *ngIf="isShow"  (click)="setTheme('later')">
    Choose Later
 </span> -->
</div>
